// 個別対応

// H1部 Teaserコンテナ
.breadcrumb + main.container .cmp-container .aem-Grid .cmp-container--padding-top-xl.cmp-container--padding-bottom-xl .cmp-container:has(h1) {
  padding: 0;
  h1 {
    padding-top: 48px;

    @include media-breakpoint-up{
      margin-bottom: 80px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 0px;
    }
  }
}
