@mixin cmp-container--padding-top($pc, $sp) {
  > .cmp-container {
    @include media-breakpoint-down(md) {
      padding-top: #{$sp}px;
    }

    @include media-breakpoint-up(md) {
      padding-top: #{$pc}px;
    }
  }
}


.cmp-container--padding-top-xs {
  @include cmp-container--padding-top(8, 8);
}

.cmp-container--padding-top-s {
  @include cmp-container--padding-top(16, 16);
}

.cmp-container--padding-top-m {
  @include cmp-container--padding-top(32, 32);
}

.cmp-container--padding-top-l {
  @include cmp-container--padding-top(48, 48);
}

.cmp-container--padding-top-ml {
  @include cmp-container--padding-top(64, 64);
}

.cmp-container--padding-top-ll {
  @include cmp-container--padding-top(72, 72);
}

.cmp-container--padding-top-xl {
  @include cmp-container--padding-top(32, 32);
}

.cmp-container--padding-top-xl2 {
  @include cmp-container--padding-top(80, 80);
}

.cmp-container--padding-top-xxl {
  @include cmp-container--padding-top(160, 80);
}