.cmp-teaser--style-hero-noimage {
  .cmp-teaser {
    display: flex;
    justify-content: center;


    &__content {
      width: 100%;
    }


    &__pretitle {
      display: none;
      margin: 0;
      color: $color-link;
      font-family: $font-family-en-accent;
      font-weight: 700;
      letter-spacing: .15em;
      text-align: center;
      font-size: 12px;
      line-height: lh(12, 15);
      word-break: break-word;
      overflow-wrap: break-word;
    }


    &__title {
      position: relative;
      font-size: 38px;
      font-weight: 700;
      line-height: 1.5;
      text-align: center;
      padding: 24px;
      padding-bottom: calc(24px + 3px);
      
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0; right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 64px;
        height: 3px;
        border-radius: 1.5px;
        background-color: #00AD92;
      }
  
      margin-bottom: 0px;

      @include media-breakpoint-down(md) {
        padding-bottom: calc(16px + 3px);
        font-size: 28px;
      }
    }

    &__description {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.8;
      max-width: 600px;
      padding: 0 24px;
      word-break: auto-phrase;

      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding-bottom: 24px;
  
      margin-bottom: 32px;

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }

    //隣接セレクターを使って、titleの後にdescriptionが続く場合のスタイルを指定
    &__title + .cmp-teaser__description {
      margin-top: 32px;
    }


    &__action-container {
      display: flex;
      justify-content: center;
    }

    &__action-link {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: auto;
      padding: 4px 32px 2px calc(16px + 1em + 16px); // 左のpaddingはアイコン＋余白サイズ
      min-height: 48px;
      text-decoration: none;
      color: #3C3C40;
      font-size: 14px;
      line-height: 1.5;
      font-weight: bold;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #CFD8DC;
      transition: background-color 0.5s ease;
      &:visited {
        text-decoration: none;
        color: #3C3C40;
      }
      &:hover {
        background-color: #F4F4FA;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 1em;
        height: 1em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%2300AD92' fill-rule='evenodd' d='M4.695 2.362c.26-.26.683-.26.943 0l5.333 5.333c.26.26.26.683 0 .943l-5.333 5.333a.667.667 0 1 1-.943-.942l4.862-4.862-4.862-4.862a.667.667 0 0 1 0-.943Z' clip-rule='evenodd'/%3e%3c/svg%3e");
        background-position: top 0.1em left;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
